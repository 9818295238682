import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as api from '../services/api';
import useRedirectUrl from './useRedirectUrl';

const useMfa = () => {
  const { push } = useHistory();
  const { webRedirectUrl } = useRedirectUrl();

  const { mutateAsync: setupMfa, error: setupMfaError, isLoading: setupMfaLoading } = useMutation(api.setupMfa, {
    onSuccess: ({ mfaToken }, { mfaPhone }) => {
      return push({
        pathname: '/verifyMfa/admin',
        search: `?returnUrl=${webRedirectUrl}&mfaToken=${mfaToken}`,
        state: { mfaPhone }
      });
    }
  });

  const {
    mutateAsync: verifyMfa,
    error: verifyMfaError,
    isLoading: verifyMfaLoading,
    reset: verifyReset
  } = useMutation(api.verifyMfa, {
    onSuccess: () => {
      window.location = webRedirectUrl;
    }
  });

  const { mutateAsync: generateMfa, error: generateMfaError, isLoading: generateMfaLoading } = useMutation(
    api.generateMfa
  );

  return {
    setupMfa,
    setupMfaLoading,
    setupMfaError,
    verifyMfa,
    verifyMfaLoading,
    verifyMfaError,
    verifyReset,
    generateMfa,
    generateMfaLoading,
    generateMfaError
  };
};

export default useMfa;
