import { createCachedSelector } from 're-reselect';
import { useCallback } from 'react';
import { useParams, generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import * as api from '../../services/api';
import useUser from '../auth/useUser';
import { ACCOUNT_BILLING_STATUS_MAP, TIME_FORMAT } from '../../constants';
import { RTL_MAP } from '../useTranslatedApp';
import useToaster from '../useToaster';
import { CONSTELLATION_ACCOUNT_IDS } from '../../utils/accounts';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => {
    const account = { ...(data || {}), language: data?.language?.iso6391 };

    const distanceUnit = account.distanceUnit || 'km';

    const config = {
      timeFormat: TIME_FORMAT[parseInt(account?.hourFormat, 10)] || TIME_FORMAT[24],
      isHost: account?.isHost,
      organizationId: account?.organizationId,
      dateFormat: account?.dateFormat || 'DD/MM/YYYY',
      shortDateFormat: account?.dateFormat?.replace('YYYY', 'YY') || 'DD/MM/YY',
      shortDateMonthFormat: account?.dateFormat?.replace('/YYYY', '') || 'DD/MM',
      firstDayOfWeek: account?.firstDayOfWeek || 0,
      currency: account?.currency?.symbol || '$',
      countryCode: account?.country?.code || 'us',
      industryId: account?.industryId,
      showOnboarding: account?.showOnboarding,
      isRtl: RTL_MAP[account?.language] || false,
      languageName: account?.language,
      payrollPeriodTypeId: account?.payrollPeriodTypeId,
      payrollStartDate: account?.payrollStartDate,
      isPayRatesEnabled: account?.payrollConfig?.isPayRatesEnabled,
      distanceUnit,
      distanceUnitShortTranslationKey: `distanceUnit_${distanceUnit}_short`,
      disableEmployeeIdField: !!account.customSettings?.disableEmployeeIdField,
      disableClientAddFromEvent: !!account.customSettings?.disableClientAddFromEvent,
      enableCostCenterFeature: !!account.customSettings?.enableCostCenterFeature,
      enableClientCostCenterFeature: !!account.customSettings?.enableClientCostCenterFeature,
      isCostCenterRequired: [CONSTELLATION_ACCOUNT_IDS, 38484].includes(account?.id),
      enablePayrollProcessFeature: !!account.customSettings?.enablePayrollProcessFeature,
      // enableRebookEmployeesFeature: !!account.customSettings?.enableRebookEmployeesFeature,
      payrollCheckInOut: !!account.customSettings?.payrollCheckInOut,
      isEngagaementAccount: !!account.customSettings?.isEngagaementAccount,
      isAccountSupplierFreemium:
        account?.settings?.billing?.status?.type === ACCOUNT_BILLING_STATUS_MAP.STAFFING_AGENCY_FREEMIUM,
      isAccountClientFreemium: account?.settings?.billing?.status?.type === ACCOUNT_BILLING_STATUS_MAP.CLIENT_FREEMIUM,
      billingStatus: account?.settings?.billing?.status?.type
    };

    return { account, config };
  }
)({
  keySelector: (data, storeKey) => storeKey
});

const useAccount = () => {
  const { accounts = [], refetch } = useUser();
  const { accountId: paramAccountId } = useParams();
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const { addResponseError } = useToaster();

  const accountId =
    parseInt(paramAccountId, 10) || accounts?.find(({ isDefault }) => !!isDefault)?.id || accounts?.[0]?.id;
  const storeKey = ['account', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchAccount({ accountId }), {
    enabled: !!accountId,
    select: (accountData) => selector(accountData, storeKey.join('#'))
  });
  const { account = {}, config = {} } = data || {};

  const setSelectedAccount = useCallback(
    (newAccountId) => {
      const newPath = generatePath(path, { accountId: newAccountId });
      push(newPath);
    },
    [path, push]
  );

  const { mutateAsync: addAccount } = useMutation(api.addAccount, { throwOnError: true, onError: addResponseError });

  return {
    isLoading,
    accountId,
    account,
    setSelectedAccount,
    config,
    addAccount,
    refetch
  };
};

export default useAccount;
