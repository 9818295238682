import { get } from 'lodash';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';

const URL_REGEX = /((https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)/gi;

const containsOnlyLetters = (stringInput) => /^[a-zA-Z]+$/.test(stringInput);
const containsURL = (value) => URL_REGEX.test(value);

export const validateMaxChars = (value = '', maxLength) =>
  maxLength && value.length > maxLength ? 'passedMaxChar' : undefined;

export const required = (value, falseInclude = false) =>
  (Array.isArray(value) && value.length === 0) ||
  value === '' ||
  value === undefined ||
  value === null ||
  (falseInclude && value === false)
    ? 'required'
    : undefined;

export const isValueBetweenDateRange = (value, dateRange) => {
  if (!value || !dateRange) {
    return 'notValid';
  }
  const { startDate, endDate } = value;

  if (!startDate || !endDate) {
    return 'notValid';
  }

  /*Using the isSameOrBefore method with the 'day' precision ensures that only the date part is considered
   in the comparison */
  return moment(startDate).isSameOrBefore(moment(dateRange.startDate), 'day') &&
    moment(endDate).isSameOrAfter(moment(dateRange.endDate), 'day')
    ? undefined
    : 'notBetweenOriginalDates';
};

export const validateContainsOnlyLetters = (value, falseInclude = false) => {
  //the empty case
  if (value === '' || value === undefined || value === null || (falseInclude && value === false)) {
    return undefined;
  }

  if (Array.isArray(value) && value.length === 0) {
    return 'enterOnlyLetters';
  }

  return containsOnlyLetters(value) ? undefined : 'enterOnlyLetters';
};

export const validateNameValue = ({ value, falseInclude = false, isRequired, maxLength = 50 }) => {
  //check required
  if (isRequired) {
    const requiredResult = required(value, falseInclude);
    if (requiredResult) {
      return requiredResult;
    }
  }

  const isValueContainsURL = containsURL(value);

  if (isValueContainsURL) {
    return 'containsURLNotAllowed';
  }

  if (maxLength) {
    const validateMaxCharsResult = validateMaxChars(value, maxLength);
    if (validateMaxCharsResult) {
      return validateMaxCharsResult;
    }
  }

  return undefined;
};

export const requiredFilters = (value) => (!value || !value?.fields?.length ? 'required' : undefined);

export const onlyPositiveNumber = (value) => (!Number.isNaN(parseFloat(value)) ? Math.abs(value) : value);

export const startsWithCapital = (value) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const validPhoneNumber = (value, countryCode) => {
  if (!value) {
    return undefined;
  }

  if (countryCode) {
    return !isValidNumber(value?.toString?.() || '', countryCode.toUpperCase()) ? 'invalidPhone' : undefined;
  }

  try {
    return parsePhoneNumber(`+${value}`)?.isValid() ? undefined : 'invalidPhone';
  } catch (err) {
    return 'invalidPhone';
  }
};

export const isValidLink = (link) => {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))|' + // OR IPv4 address
      '\\[([a-fA-F0-9:]+)\\]' + // IPv6 address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return urlRegex.test(link);
};

export const isEmailValid = (value) =>
  !value ||
  (value?.toString?.() || '').match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? true
    : false;

export const validEmail = (value) => (isEmailValid(value) ? undefined : 'invalidEmail');

export const validBirthdate = (value, isRequired = false) =>
  (!isRequired && !value) ||
  (moment().diff(moment(value, API_DATE_TIME_FORMAT), 'years') > 0 &&
    moment().diff(moment(value, API_DATE_TIME_FORMAT), 'years') <= 120)
    ? undefined
    : 'invalidBirthdate';

export const validLength = (limit) => (value) => (value.length > limit ? 'tooLarge' : undefined);

/* Passwords must include at least one character from one of the following categories - 
Uppercase letter, Lowercast letter, digits, special character (!, $, # etc)  and be min of 10 chars length 
*/

export const passwordIndicator = (password) => {
  const isNotValid = passwordIndicatorData(password);

  return isNotValid === 'strongPassword' ? undefined : 'notValidPassword';
};

export const passwordIndicatorData = (password) => {
  if (!password || password.length < 10) {
    return false;
  }

  // const mediumRegex = new RegExp('^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$', 'g');
  const strongRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$#%&*?,£()^_><+=])[A-Za-z\d!@$#%&*?,£()^_><+=]{10,}$/;

  if (strongRegex.test(password)) {
    return 'strongPassword';
  }

  // if (mediumRegex.test(password)) {
  //   return 'mediumPassword';
  // }

  return 'weekPassword';
};

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const IGNORE_SPLIT_ARRAY = ['branches', 'positions', 'workDays', 'tags', 'preferredClients', 'blockedClients', 'links'];

export const formatFieldsArrayToMap = (data = {}, generatePayrollFields = {}) => {
  return {
    ...data,
    fields: (data.fields || []).reduce((prev, { id, value }) => ({ ...prev, [`field-${id}`]: value }), {}),
    payrollFields: (generatePayrollFields || []).reduce(
      (prev, { id, value }) => ({ ...prev, [`field-${id}`]: value }),
      {}
    )
  };
};

export const formatPrivilegesArrayToMap = (admin = {}) => ({
  privilegesMap: admin.privileges?.reduce?.((prev, { id }) => ({ ...prev, [`privilege-${id}`]: true }), {}) || {}
});

// split array to added/edited/deleted arrays
const splitDirtyArrayToStatus = (key, values) => ({
  added: get(values, key)
    .filter(({ status }) => status === 'added')
    .map((item) => (item.id < 0 ? { ...item, id: undefined } : item)),
  deleted: get(values, key)
    .filter(({ status }) => status === 'deleted')
    .map(({ id }) => id),
  edited: get(values, key).filter(({ status }) => status === 'edited')
});

export const getDirtyFields = (values, dirtyFields) =>
  Object.entries(dirtyFields)
    .filter(([, value]) => value)
    .reduce((prev, [key]) => {
      if (key.includes('fields.field-')) {
        const [, fieldId] = new RegExp(`fields.field-(.*)`).exec(key);
        return { ...prev, fields: [...(prev.fields || []), { id: fieldId, value: get(values, key) || '' }] };
      }

      if (key.includes('payrollFields.field-')) {
        const [, fieldId] = new RegExp(`payrollFields.field-(.*)`).exec(key);
        return {
          ...prev,
          payrollFields: [
            ...(prev.payrollFields || []),
            { id: parseInt(fieldId, 10), value: Number(get(values, key)) || 0 }
          ]
        };
      }

      if (!IGNORE_SPLIT_ARRAY.includes(key) && Array.isArray(values[key])) {
        const arraySplitting = splitDirtyArrayToStatus(key, values);
        return { ...prev, [key]: { ...prev[key], ...arraySplitting } };
      }

      if (key === 'wages.hourly') {
        const wagesHourly = splitDirtyArrayToStatus(key, values);
        return { ...prev, wages: { ...prev.wages, hourly: { ...(prev.wages?.hourly || {}), ...wagesHourly } } };
      }

      if (key === 'wages.global') {
        const wagesGlobal = splitDirtyArrayToStatus(key, values);
        return { ...prev, wages: { ...prev.wages, global: { ...(prev.wages?.global || {}), ...wagesGlobal } } };
      }

      if (key.includes('config.')) {
        const [, configKey] = new RegExp(`config.(.*)`).exec(key);
        return { ...prev, [configKey]: get(values, key) };
      }

      if (key.includes('mobileClockMethods.')) {
        return prev;
      }

      if (key.includes('payrollConfig.')) {
        return prev;
      }

      if (key.includes('privilegesMap')) {
        return prev;
      }

      return { ...prev, [key]: get(values, key, '') };
    }, {});
