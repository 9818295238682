import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as api from '../services/api';
import useRedirectUrl from './useRedirectUrl';

const useAuth = () => {
  const { push } = useHistory();

  const { webRedirectUrl, crewRedirectUrl } = useRedirectUrl();

  const { mutateAsync: loginAsAdmin } = useMutation(api.loginAsAdmin, {
    throwOnError: true,
    onSuccess: ({ isMfaEnabled, isMfaConfigured, mfaToken, isValidMFARememberMe, maskedMfaPhone }) => {
      if (isMfaEnabled) {
        if (!isMfaConfigured) {
          return push(`/setupMfa/admin?returnUrl=${webRedirectUrl}&mfaToken=${mfaToken}`);
        }
        if (!isValidMFARememberMe) {
          return push({
            pathname: '/verifyMfa/admin',
            search: `?returnUrl=${webRedirectUrl}&mfaToken=${mfaToken}`,
            state: { mfaPhone: maskedMfaPhone }
          });
        }
      }
      window.location = webRedirectUrl;
    }
  });

  const { mutateAsync: loginAsUser } = useMutation(api.loginAsUser, {
    onSuccess: () => (window.location = crewRedirectUrl),
    throwOnError: true
  });

  const { mutateAsync: forgotPassword } = useMutation(api.forgotPassword);

  const { mutateAsync: resetPassword } = useMutation(api.resetPassword, { throwOnError: true });

  const { mutateAsync: signupUser } = useMutation(api.signupUser, { throwOnError: true });

  const { mutateAsync: logout } = useMutation(() => api.logoutAdmin(), {
    onSuccess: () => push(`/login/admin`)
  });

  const { mutateAsync: unsubscribe } = useMutation(api.unsubscribe, { throwOnError: true });

  const { mutateAsync: validateResetToken } = useMutation(api.validateResetToken);

  return {
    loginAsAdmin,
    loginAsUser,
    forgotPassword,
    resetPassword,
    signupUser,
    logout,
    unsubscribe,
    validateResetToken
  };
};

export default useAuth;
