import { post } from '@ubeya/shared/utils/http';

export const loginAsAdmin = ({ email, password, tokenId, type, rememberMeToken }) =>
  post('/admin/login', { email, password, tokenId, type, rememberMeToken });

export const signupUser = ({ phone, recaptchaToken }) =>
  post(
    '/user/signup',
    { phone },
    {
      headers: {
        'x-recaptcha-token': recaptchaToken
      }
    }
  );

export const loginAsUser = ({ phone, code, token }) => post('/user/login', { phone, code, token });

export const forgotPassword = ({ email }) => post('/admin/forgot', { email });
export const unsubscribe = ({ email, token }) => post('/unsubscribe', { email, token });

export const resetPassword = ({ password, token }) =>
  post('/admin/reset', { password }, { headers: { Authorization: `Bearer ${token}` } });

export const logoutAdmin = () => post('/admin/logout');

export const logoutUser = () => post('/user/logout');

export const setupMfa = (data) => post('/admin/mfa-setup', data);

export const verifyMfa = (data) => post('/admin/mfa-verify', data);

export const generateMfa = (data) => post('/admin/mfa-generate', data);

export const validateResetToken = ({ token }) =>
  post('/admin/validate-reset-token', {}, { headers: { Authorization: `Bearer ${token}` } });
